define("discourse/plugins/discourse-ai/discourse/routes/admin-plugins-show-discourse-ai-tools", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DiscourseAiToolsRoute extends _discourse.default {
    model() {
      return this.store.findAll("ai-tool");
    }
  }
  _exports.default = DiscourseAiToolsRoute;
});