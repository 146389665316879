define("discourse/plugins/discourse-ai/discourse/routes/admin-plugins-show-discourse-ai-tools-show", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    async model(params) {
      const allTools = this.modelFor("adminPlugins.show.discourse-ai-tools");
      const id = parseInt(params.id, 10);
      return allTools.findBy("id", id);
    },
    setupController(controller, model) {
      this._super(controller, model);
      const toolsModel = this.modelFor("adminPlugins.show.discourse-ai-tools");
      controller.set("allTools", toolsModel);
      controller.set("presets", toolsModel.resultSetMeta.presets);
    }
  });
});