define("discourse/plugins/discourse-ai/discourse/routes/admin-plugins-show-discourse-ai-tools-new", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    async model() {
      const record = this.store.createRecord("ai-tool");
      return record;
    },
    setupController(controller, model) {
      this._super(controller, model);
      const toolsModel = this.modelFor("adminPlugins.show.discourse-ai-tools");
      controller.set("allTools", toolsModel);
      controller.set("presets", toolsModel.resultSetMeta.presets);
    }
  });
});